
div {
  background-color: white;
  /* color: #A9A9A9; */
  
  opacity: 1;
  text-align: center;
}

h1 {
	text: black;
}

input { width: 50px; }

.brandA {
	color: #7d383b; 
}

.brandB {
	color: #7d383b; 
}

.rowC { display:flex; 
		flex-direction:row;
		flex-flow: row wrap;
		text-align: center;
		justify-content: center;
		 }